<template>
  <div class="modal-container">
    <button class="close-button" @click="closeClicked">X</button>

    <div class="bubblegum" :style="{ fontSize: getFontSize(0.9) }">Tinggalkan sepatah dua kata ucapan bagi pengantin,</div>
    <div class="aguafina" :style="{ fontSize: getFontSize(2), marginTop: getFontSize(0.2) }">Adeeb & Hanna</div>
    
    <div class="wish-container">
      <div class="wish-row">
        <div class="bubblegum caption" :style="{ fontSize: getFontSize(1), marginRight: '5px' }">Tulisan : </div>
        <select v-model="selectedFont" :style="{ fontSize: getFontSize(1), fontFamily: selectedFont }">  
          <option v-for="(f, i) in allFonts" :key="i" :value="f['font']" :style="{ fontFamily: f['font'] }">{{ f['name'] }}</option>
        </select>
      </div>
      <textarea :style="{ fontSize: getFontSize(1), fontFamily: selectedFont }" v-model="wishText"></textarea>
      <div class="wish-row" style="justify-content: flex-end">
        <div class="bubblegum caption" :style="{ fontSize: getFontSize(1), marginRight: '5px', marginTop: '10px' }">Dari : </div>
        <input type="text" :style="{ fontSize: getFontSize(1), fontFamily: selectedFont }" v-model="wishFrom">
      </div>
    </div>

    <button class="bubblegum" :style="{ fontSize: getFontSize(1), marginTop: '10px' }" :disabled="submitLoad" @click="submitWish">
    <!-- <button class="bubblegum" :style="{ fontSize: getFontSize(1), marginTop: '10px' }" :disabled="submitLoad" @click="showPopup = true"> -->
      <img v-if="submitLoad" :style="{ height: getFontSize(1) }" src="../assets/HeartLoad.gif" alt="">
      <div v-else>Hantar</div>
    </button>

    <Transition name="popup-transition">
      <div v-if="showPopup" class="popup-background bubblegum" @click="showPopup = false">
        <div class="popup-content" :style="{ maxWidth: `calc(100% - ${getFontSize(5)})` }">
          <div :style="{ fontSize: getFontSize(1), margin: `${getFontSize(1)} 0` }">{{ popupContent }}</div>
          <button class="bubblegum" @click="showPopup = false" :style="{ fontSize: getFontSize(1) }">Tutup</button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, watch } from 'vue';
import { getFontSize } from '../js/fontHelper';
import axios from 'axios';

export default defineComponent({
  components: {
  },
  props: {
  },
  setup(props, { emit }) {
    //#region Data
    const allFonts = ref([
      { name: 'Amita', font: `'Amita', cursive` },
      { name: 'Caveat', font: `'Caveat', cursive` },
      { name: 'Covered By Your Grace', font: `'Covered By Your Grace', cursive` },
      { name: 'Crafty Girls', font: `'Crafty Girls', cursive` },
      { name: 'Dancing Script', font: `'Dancing Script', cursive` },
      { name: 'Great Vibes', font: `'Great Vibes', cursive` },
      { name: 'Italianno', font: `'Italianno', cursive` },
      { name: 'Just Me Again Down Here', font: `'Just Me Again Down Here', cursive` },
      { name: 'Kalam', font: `'Kalam', cursive` },
      { name: 'Pacifico', font: `'Pacifico', cursive` },
      { name: 'Permanent Marker', font: `'Permanent Marker', cursive` },
      { name: 'Shadows Into Light', font: `'Shadows Into Light', cursive` },
    ]);
    const selectedFont = ref(`'Amita', cursive`);
    const submitLoad = ref(false);
    const wishText = ref('');
    const wishFrom = ref('');
    const showPopup = ref(false);
    const popupContent = ref('');
    const popupProm = ref(null);
    //#endregion
    
    //#region Methods
    const closeClicked = () => {
      emit('close');
    }
    const submitWish = async () => {
      if (!wishText.value && !wishFrom.value) {
        popupContent.value = 'Alaaa, takkan nak bagi ucapan kosong kot.';
        showPopup.value = true;
        return;
      }
      if (wishText.value && !wishFrom.value) {
        popupContent.value = 'Agak agak, siapa yang bagi ucapan ni eh?'
        showPopup.value = true;
        return;
      }
      if (!wishText.value && wishFrom.value) {
        popupContent.value = `Aik ${wishFrom.value}, janganlah bagi ucapan kosong, cane nak bace?`;
        showPopup.value = true;
        return;
      }

      submitLoad.value = true;
      await axios.post('https://api.reeqzan.com/AdeebHannaWish/SaveWish', {
        font: selectedFont.value,
        wishfrom: wishFrom.value,
        text: wishText.value,
        time: new Date().format('yyyy-MM-dd HH:mm:ss')
      });
      submitLoad.value = false;
      
      popupContent.value = `Awww, terharunya dapat ucapan. Terima kasih ${wishFrom.value} untuk ucapan. 🥰`;
      showPopup.value = true;

      await new Promise(res => popupProm.value = res);

      emit('close', true);
    }
    //#endregion

    //#region Watcher
    watch(showPopup, (val) => {
      if (!val && popupProm.value) {
        popupProm.value();
      }
    })
    //#endregion
    
    return {
      props,
      getFontSize,

      //#region Data
      allFonts,
      selectedFont,
      submitLoad,
      wishText,
      wishFrom,
      showPopup,
      popupContent,
      //#endregion
      
      //#region Methods
      closeClicked,
      submitWish,
      //#endregion
    }
  },
})
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  color: red;
  background-color: transparent;
  border: none;
  font-weight: bold;
}
.wish-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wish-container > textarea {
  width: 100%;
  max-width: 600px;
  height: 30vh;
  resize: none;
  background-color: transparent;
  margin-top: 10px;
}
.wish-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  justify-content: center;
}
.wish-row > .caption {
  white-space: nowrap;
  background-color: transparent;
}
.wish-row > select {
  min-width: 50%;
  max-width: 50%;
  background-color: transparent;
}
.wish-row > input {
  min-width: 80%;
  max-width: 80%;
  background-color: transparent;
  margin-top: 10px;
}
</style>