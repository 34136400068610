<template>
  <div class="modal-container wish-list-main">
    <button class="close-button" @click="closeClicked">X</button>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1) }">Senarai Ucapan untuk pengantin,</div>
    <div class="aguafina" :style="{ fontSize: getFontSize(2), marginTop: getFontSize(0.2) }">Adeeb & Hanna</div>

    <button class="bubblegum" :style="{ fontSize: getFontSize(1), margin: `${getFontSize(0.5)} 0` }" @click="showAddWish = true">Tambah Ucapan</button>

    <img v-if="loadingWishes" :style="{ height: getFontSize(2) }" src="../assets/HeartLoad.gif">
    <div v-else class="wish-list-container">
      <div class="wish-item-container" v-for="(w, i) in wishesList" :key="i" :style="{ padding: getFontSize(0.3) }">
        <div class="bubblegum" :style="{ fontSize: getFontSize(0.8) }">Pada: <span>{{ new Date(w['time']).format('dd MMM yyyy HH:mm:ss') }}</span></div>
        <div class="bubblegum" :style="{ fontSize: getFontSize(0.8) }">Dari: <span>{{ w['wishfrom'] }}</span></div>
        <div :style="{ fontSize: getFontSize(1), fontFamily: w['font'] }">{{ w['text'] }}</div>
      </div>
    </div>

    <Transition name="modal-transition">
      <wishes-modal v-if="showAddWish" @close="addWishModalClosed"></wishes-modal>
    </Transition>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, onMounted, ref } from 'vue';
import WishesModal from './WishesModal.vue';
import axios from 'axios';
import { getFontSize } from '../js/fontHelper';

export default defineComponent({
  components: {
    WishesModal
  },
  setup(props, { emit }) {
    //#region Lifecycle
    onMounted(() => {
      loadWishes();
    })
    //#endregion

    //#region Data
    const showAddWish = ref(false);
    const wishesList = ref([]);
    const loadingWishes = ref(false);
    //#endregion
    
    //#region Methods
    const closeClicked = () => {
      emit('close');
    }
    const addWishModalClosed = (added) => {
      showAddWish.value = false;
      if (added) {
        wishesList.value = [];
        loadWishes();
      }
    }
    const loadWishes = async () => {
      loadingWishes.value = true;
      setTimeout(async () => {
        wishesList.value = (await axios.get('https://api.reeqzan.com/AdeebHannaWish/GetWishes'))['data'];
        loadingWishes.value = false;
      }, 500);
    }
    //#endregion
    
    return {
      getFontSize,

      //#region Data
      showAddWish,
      wishesList,
      loadingWishes,
      //#endregion
      
      //#region Methods
      closeClicked,
      addWishModalClosed,
      //#endregion
    }
  },
})
</script>

<style scoped>
.wish-list-main {
  overflow: hidden;
}
.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  color: red;
  background-color: transparent;
  border: none;
  font-weight: bold;
}
.wish-list-container {
  height: 100%;
  width: 100%;
  max-width: 600px;
  overflow: auto;
}
.wish-item-container {
  border: 1px solid gray;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.wish-item-container:not(:first-child) {
  margin-top: 10px;
}
.wish-text {
  text-align: left;
}
</style>