import { ref } from "vue";

const windowWidth = ref();

export const getFontSize = (initialSize) => {
  const sizePerPx = (initialSize || 1) / 390;
  return `${(windowWidth.value > 600 ? 600 : windowWidth.value) * sizePerPx}em`;
}

export const startSizeListening = () => {
  windowWidth.value = window.screen.width;
  window.addEventListener('resize', () => windowWidth.value = window.screen.width );
}