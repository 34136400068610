<template>
  <div class="main-app-container" ref="mainAppContainer">
    <div class="card-container">
      <img class="card-boxes" src="./assets/Boxes.png" alt="">
      <div class="bubblegum" :style="{ marginTop: getFontSize(3), fontSize: getFontSize(1) }">PERUTUSAN RAJA SEHARI</div>

      <div class="name-row aguafina" :style="{ marginTop: getFontSize(3) }">
        <div class="name-row-item" :style="{ height: getFontSize(0.9), justifyContent: 'flex-end', fontSize: getFontSize(4) }">Adeeb</div>
        <div class="name-row-item"></div>
      </div>
      <div class="name-row aguafina" :style="{ height: 0, zIndex: 1, fontSize: getFontSize(2.5) }">&</div>
      <div class="name-row aguafina">
        <div class="name-row-item"></div>
        <div class="name-row-item" :style="{ height: getFontSize(1.4), fontSize: getFontSize(4) }">Hanna</div>
      </div>

      <div class="bubblegum" :style="{ fontSize: getFontSize(1) }">14 JANUARI 2023</div>
      <div class="bubblegum" :style="{ fontSize: getFontSize(0.75) }">21 JUMADIL AKHIRAH 1444H</div>

      <img class="bottom-flower" src="./assets/BottomFlower.png">
      <img class="top-flower-front" :style="{ top: `-${getFontSize(1)}`, right: `-${getFontSize(1)}` }" src="./assets/TopFlowerFront.png">
    </div>

    <div class="song-section">
      <audio ref="audioTag" @ended="audioEnded">
        <source :src="currentSong" type="audio/mpeg">
      </audio>
      <div class="previous-song" @click="prevClicked" :style="{ height: getFontSize(2), width: getFontSize(2), margin: getFontSize(0.1) }">
        <Transition name="prev">
          <img v-if="!prevSong" src="@/assets/Previous.png" alt="">
        </Transition>
        <Transition name="prev">
          <img v-if="prevSong" src="@/assets/Previous.png" alt="">
        </Transition>
      </div>
      <div class="play-pause-song" @click="playPause" :style="{ height: getFontSize(2), width: getFontSize(2), margin: getFontSize(0.1) }">
        <Transition name="play">
          <img v-if="!playState" src="@/assets/Play.png" alt="">
        </Transition>
        <Transition name="play">
          <img v-if="playState" src="@/assets/Pause.png" alt="">
        </Transition>
      </div>
      <div class="next-song" @click="nextClicked" :style="{ height: getFontSize(2), width: getFontSize(2), margin: getFontSize(0.1) }">
        <Transition name="next">
          <img v-if="!nextSong" src="@/assets/Next.png" alt="">
        </Transition>
        <Transition name="next">
          <img v-if="nextSong" src="@/assets/Next.png" alt="">
        </Transition>
      </div>
    </div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1) }">{{ songName }}</div>

    <img class="bismillah-word" :style="{ margin: `${getFontSize(3)} 0` }" src="./assets/Bismillah.png">
    <div class="aguafina" :style="{ fontSize: getFontSize(1.5) }">Ramlan Bin Sanin</div>
    <div class="aguafina" :style="{ fontSize: getFontSize(1) }">&</div>
    <div class="aguafina" :style="{ fontSize: getFontSize(1.5) }">Rapidah Binti Mohamad</div>
    <br>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1) }">Dengan Penuh Rasa Kesyukuran Ke Hadrat Ilahi,</div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1) }">Kami Menjemput</div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1) }">YBhg. Dato' / datin / tuan / puan / encik / cik</div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1) }">Ke Majlis Perkahwinan Putera Kami</div>

    <div class="aguafina" :style="{ fontSize: getFontSize(2), marginTop: getFontSize(0.3) }">Mohd Fizree Adeeb bin Ramlan</div>
    <div class="aguafina" :style="{ fontSize: getFontSize(2) }">&</div>
    <div class="aguafina" :style="{ fontSize: getFontSize(2) }">Nor Hanna binti Nor Azhar</div>

    <div class="bubblegum" :style="{ fontSize: getFontSize(1), marginTop: getFontSize(1) }">Pada hari Sabtu,</div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1.2) }">14 Januari 2023</div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(0.8) }">Bersamaan 21 Jumadil Akhirah 1444H</div>

    <div class="bubblegum" :style="{ fontSize: getFontSize(1), marginTop: getFontSize(1) }">Bertempat di,</div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(1.2) }">Ruang Event Space (Dewan MBSA Seksyen 13)</div>
    <div class="bubblegum" :style="{ fontSize: getFontSize(0.8) }">11, Jalan Sepak Raga 13/13,<br>Seksyen 13, 40100 Shah Alam, Selangor</div>

    <div class="direction-section" :style="{ marginTop: `${getFontSize(0.3)}` }">
      <div class="direction-item">
        <a href="https://waze.com/ul?ll=3.0838232063007394,101.54000963500746&navigate=yes">
          <img :style="{ height: `${getFontSize(3)}` }" src="./assets/WazeIcon.png" alt="">
          <div class="direction-item bubblegum" :style="{ fontSize: `${getFontSize(1)}` }">Waze</div>
        </a>
      </div>
      <div class="direction-item">
        <a href="https://www.google.com/maps/search/?api=1&query=3.0838232063007394,101.54000963500746">
          <img :style="{ height: `${getFontSize(3)}` }" src="./assets/GMapIcon.png" alt="">
          <div class="direction-item bubblegum" :style="{ fontSize: `${getFontSize(1)}` }">Google Maps</div>
        </a>
      </div>
    </div>

    <div class="bubblegum" :style="{ fontSize: `${getFontSize(1.2)}`, marginTop: `${getFontSize(1)}` }">Aturcara Majlis</div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(1.2)}` }">Jamuan Makan</div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(0.8)}` }">11:00 pagi - 04:00 petang</div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(1.2)}` }">Ketibaan Pengantin</div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(0.8)}` }">12:30 tengah hari</div>

    <div class="bubblegum" :style="{ fontSize: `${getFontSize(1.2)}`, marginTop: `${getFontSize(1)}` }">Hubungi</div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(0.8)}` }">Ramlan - <a href="tel:+60197758110">019 775 8110</a></div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(0.8)}` }">Rapidah - <a href="tel:+60176517663">017 651 7663</a></div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(0.8)}` }">Fatehah - <a href="tel:+60126952091">012 695 2091</a></div>
    <div class="bubblegum" :style="{ fontSize: `${getFontSize(0.8)}` }">Ayu - <a href="tel:+601126264829">011 2626 4829</a></div>

    <div :style="{ minHeight: `${getFontSize(2)}` }"></div>
    <div class="full-background">
      <img class="bottom-flower-behind" :style="{ bottom: `-${getFontSize(2.2)}` }" src="./assets/BottomFlowerBehind.png">
      <img class="top-flower-behind" :style="{ top: `-${getFontSize(1)}`, left: `-${getFontSize(1)}` }" src="./assets/TopFlowerBehind.png">
    </div>

    <button class="bubblegum" @click="showWishListModal = true" :style="{ fontSize: getFontSize(1), marginBottom: getFontSize(2) }">Tinggalkan Ucapan</button>

    <Transition name="modal-transition">
      <wish-list-modal v-if="showWishListModal" @close="showWishListModal = false"></wish-list-modal>
    </Transition>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, defineAsyncComponent, defineComponent, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { getFontSize, startSizeListening } from './js/fontHelper';
import WishListModal from './components/WishListModal.vue';

export default defineComponent({
  name: 'App',
  components: {
    WishListModal
  },
  setup() {
    //#region Lifecycle
    onMounted(() => {
    })
    onBeforeMount(() => {
      startSizeListening();
      // window.addEventListener("resize", resizeHandler);
      // window.addEventListener('scroll', scrollHandler);
    })
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scrollHandler);
    })
    //#endregion

    //#region Computed
    const songName = computed(() => {
      let name = songList.value[currentSongIndex.value];
      let songArtist = name.split('-');
      return `${songArtist[0].split('_').join(' ')} by ${songArtist[1].split('_').join(' ')}`;
    })
    //#endregion

    //#region Data
    const mainAppContainer = ref(null);
    const blurAmount = ref(3);
    const showWishListModal = ref(false);
    const wishComp = ref(defineAsyncComponent(() => import('./components/WishesModal.vue')));
    const playState = ref(false);
    const prevSong = ref(false);
    const nextSong = ref(false);
    const audioTag = ref(null);
    const songList = ref([ 'Selamat_Pengantin_Baru-Saloma', 'Selamat_Pengantin_Baru-Anita_Sarawak', 'Selamat_Pengantin_Baru-Wann' ]);
    // const songList = ref([ `Hell's_Kitchen-Sound_Effect`, 'Fart-Sound-Effect', 'Alarm-Sound_Effect' ]);
    const currentSongIndex = ref(0);
    const currentSong = ref();
    //#endregion
    
    //#region Methods
    const scrollHandler = (e) => {
      let scrollYValue = e.target.scrollingElement.scrollTop;
      const fromTopPx = getFontSize(0.6) * 390;
      if (scrollYValue > fromTopPx) {
        scrollYValue = fromTopPx;
      }
      
      blurAmount.value = 3 - (scrollYValue / fromTopPx * 3);
    }
    const audioEnded = () => {
      playState.value = false;
      nextClicked();

      setTimeout(() => {
        playState.value = true;
      }, 300);
    }
    const playPause = () => {
      playState.value = !playState.value;

      if (playState.value && !currentSong.value) {
        currentSong.value = require(`@/assets/songs/${songList.value[currentSongIndex.value]}.mp3`);
        audioTag.value.load();
      }

      if (playState.value) { audioTag.value.play() }
      else { audioTag.value.pause(); }
    }
    const prevClicked = () => {
      prevSong.value = !prevSong.value;
      currentSongIndex.value--;
      if (currentSongIndex.value < 0) {
        currentSongIndex.value = songList.value.length - 1;
      }
      currentSong.value = require(`@/assets/songs/${songList.value[currentSongIndex.value]}.mp3`);
      audioTag.value.load();
      audioTag.value.play();

      if (!playState.value) {
        playState.value = true;
      }
    }
    const nextClicked = () => {
      nextSong.value = !nextSong.value;
      currentSongIndex.value++;
      if (currentSongIndex.value == songList.value.length) {
        currentSongIndex.value = 0;
      }
      currentSong.value = require(`@/assets/songs/${songList.value[currentSongIndex.value]}.mp3`);
      audioTag.value.load();
      audioTag.value.play();

      if (!playState.value) {
        playState.value = true;
      }
    }
    //#endregion
    
    //#region Watcher
    watch(showWishListModal, (e) => {
      document.getElementsByTagName('body')[0].style.overflow = e ? 'hidden' : 'auto';
    })
    // watch(playState, (e) => {
    //   if (e) {
    //     if (!currentSong.value) {
    //       currentSong.value = require(`@/assets/songs/${songList.value[currentSongIndex.value]}.mp3`);
    //       audioTag.value.load();
    //     }
    //     audioTag.value.play();
    //   } else {
    //     audioTag.value.pause();
    //   }
    // })
    //#endregion

    return {
      getFontSize,

      //#region Data
      mainAppContainer,
      blurAmount,
      showWishListModal,
      wishComp,
      playState,
      prevSong,
      nextSong,
      audioTag,
      songList,
      currentSongIndex,
      currentSong,
      songName,
      //#endregion
      
      //#region Methods
      audioEnded,
      playPause,
      prevClicked,
      nextClicked,
      //#endregion
    }
  },
})
</script>

<style scoped>
.main-app-container {
  width: 100vw;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 5px 5px gray;
  text-align: center;
  box-sizing: border-box;
}
.card-container {
  width: calc(100% - 120px);
  max-width: 540px;
  /* background-color: white; */
  background: linear-gradient(32deg, rgba(252,216,255,1) -10%, rgba(255,255,255,1) 50%, rgba(252,216,255,1) 110%);
  margin-top: 30px;
  border-radius: 50px;
  padding: 20px;
  padding-bottom: 0;
  box-shadow: 0 0 10px 5px rgb(25,25,25);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.name-row {
  display: flex;
  align-items: center;
  width: 100%;
  /* background-color: green; */
  justify-content: center;
}
.name-row-item {
  display: flex;
  align-items: center;
  max-width: 50%;
  min-width: 50%;
  /* background-color: blue; */
}
.bottom-flower {
  width: 100%;
  bottom: 0px;
  position: relative;
}
.top-flower-front {
  width: 50%;
  position: absolute;
}
.card-boxes {
  position: absolute;
  top: -5%;
  width: 117%;
  height: 105%;
  z-index: 1;
}
.bismillah-word {
  width: 60%;
  margin: 30px 0;
}
.direction-section {
  display: flex;
  width: 100%;
}
.direction-item {
  min-width: 50%;
  max-width: 50%;
}
.direction-item > a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.full-background {
  background: linear-gradient(-32deg, rgba(252,216,255,1) 10%, rgba(255,255,255,1) 50%, rgba(252,216,255,1) 90%);
  height: calc(100%);
  width: calc(100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -999;
}
.bottom-flower-behind {
  position: absolute;
  right: 0;
  width: 50%;
}
.top-flower-behind {
  position: absolute;
  width: 40%;
}
</style>

<style>
body {
  margin: 0;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: overlay;
  display: flex;
  justify-content: center;
  user-select: none;
}
body > * {
  box-sizing: border-box;
}
.bubblegum {
  font-family: 'Bubblegum Sans', cursive;
  z-index: 5;
}
.aguafina {
  font-family: 'Aguafina Script', cursive;
  z-index: 5;
  line-height: 1em;
}
button:active {
  outline: none;
}
button:focus {
  outline: none;
}
button {
  background-color: rgb(248, 175, 255);
  border: 1px solid rgb(238, 48, 255);
  padding: 5px 15px;
  border-radius: 10px;
  cursor: pointer;
}
button:disabled {
  opacity: 0.75;
}

.modal-transition-enter-from,
.modal-transition-leave-to {
  transform: translateY(100%);
}
.popup-transition-enter-from,
.popup-transition-leave-to {
  opacity: 0;
}
.popup-transition-enter-from .popup-content,
.popup-transition-leave-to .popup-content {
  transform: translateY(100px);
  opacity: 0;
}

.modal-container {
  background-color: rgba(252,216,255,0.75);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  z-index: 99;
  transition: 0.3s;
  padding: 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-background {
  background-color: rgba(131, 0, 143, 0.1);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-content {
  max-width: 500px;
  background-color: rgba(254,238,255,1);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(238, 48, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.popup-header {
  font-weight: bold;
}

.song-section {
  display: flex;
  width: 100%;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}
.previous-song, .play-pause-song, .next-song {
  border: 1px solid gray;
  border-radius: 100%;
  box-shadow: 0px 0px 5px black;
  cursor: pointer;
  transition: 0.1s;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.previous-song:active, .play-pause-song:active, .next-song:active {
  transform: scale(0.9);
  box-shadow: none;
}
.previous-song img, .play-pause-song img, .next-song img {
  height: 40%;
  width: 40%;
  position: absolute;
}

.play-enter-active,
.play-leave-active {
  transition: 0.1s;
}
.play-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}
.play-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.next-enter-active,
.next-leave-active {
  transition: 0.1s;
}
.next-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
.next-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.prev-enter-active,
.prev-leave-active {
  transition: 0.1s;
}
.prev-enter-from {
  transform: translateX(100%);
  opacity: 0;
}
.prev-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

input, textarea, select {
  border: 1px solid rgb(238, 48, 255);
  border-radius: 10px;
}
input:active, input:focus, textarea:focus, textarea:active, select:active, select:focus {
  outline: none;
}
</style>